<script>
  import * as langHandler from '@/utils/language-handler.js';
  import mq from "@/stores/mq.js";
  import { onMount } from "svelte";
  import sanitizeHtml from "sanitize-html";

  // Efficiency dashboard
  import ChartSection from "./ChartSection.svelte";
  import LineChart from "./Chart__Line.svelte";
  import ArrowChart from "./Chart__Arrow.svelte";
  import SectorDropdown from "./SectorDropdown.svelte"
  import ScenarioSelector from "./ScenarioSelector.svelte";
  import BottomNav from './BottomNav.svelte';
  import InfoTooltip from "./InfoTooltip.svelte";

  import { ghgData, getValue } from '@/stores/data.js'
  import { selectedSector, selectedProjection } from '@/stores/interactivity.js'
  import { chartColours, years, projectionKeys } from '@/constants.js';
  import { formatDefaultVal, formatPercentageTracker, addNZPRange, usePopAsActivity } from "@/utils/dataHelpers.js"

  let comparisonView = false;

  $: processGHGdata = (_, sector) => {
    let data = _.map(x => x).filter(d => d.sector === sector) // make a shallow copy
    // Create NZP_range with [lower, upper]
    data = addNZPRange(data, energyIntensityKey);
    return data;
  }

  $: processedGHGData = $ghgData ? processGHGdata($ghgData, $selectedSector.id) : null
  $: projectionVars = comparisonView ? projectionKeys : [$selectedProjection.id]

  $: usePopulation = usePopAsActivity($selectedSector.id);
  $: energyIntensityKey = usePopulation ? "energy_per_pop" :  "energy_per_gdp";

  onMount(() => {
    const headerHeight = document.getElementsByClassName("site-header")[0].offsetHeight
    if (window.scrollY > headerHeight) {
      window.scrollTo(0,headerHeight);
    } 
  });

  const projectionStartYear = Number(years.projection[0])

  $: targetIntensityChange = ($getValue($selectedSector.id, 2030, d => d[energyIntensityKey], "NZP_upper") - getIntensityValueByYr(projectionStartYear))/ Math.abs(getIntensityValueByYr(projectionStartYear));
  $: actualIntensityChange= ($getValue($selectedSector.id, 2030, d =>d[energyIntensityKey], $selectedProjection.id) - getIntensityValueByYr(projectionStartYear))/ Math.abs(getIntensityValueByYr(projectionStartYear));

  $: getIntensityValueByYr = year => $getValue($selectedSector.id, year, d => d[energyIntensityKey], year < years.projection[0] ? "historical" : $selectedProjection.id);
  $: formatIntensityVal = d => formatDefaultVal(d) + ' ' + (usePopulation ? langHandler.getUnits('energy_per_pop_intext') : langHandler.getUnits('energy_per_gdp_intext'))
</script>

<div class="text-wrapper">
  <div class="centered-text">
    {#if (langHandler.locale) == 'fr'}
      <h1 class="section-title fr">Le rôle de <span class="efficiency">l'efficacité</span> dans la réduction des émissions</h1>
      <p>L’efficacité, ou l’intensité énergétique de l’activité, est un facteur clé de réduction des émissions, qui consiste à réduire la quantité d’énergie nécessaire pour fournir un service – transporter des biens ou chauffer une maison, par exemple – en utilisant cette énergie de manière intelligente.</p>
    {:else}
      <h1 class="section-title">Role of <span class="efficiency">efficiency</span> in reducing emissions</h1>
      <p>Efficiency, or the energy intensity of activity, is a key driver of emissions reductions, as it reduces the amount of energy needed to provide a service—transporting goods or heating a home, for example—by using that energy in smarter ways.</p>
    {/if}
  </div>
</div>

<ChartSection title={langHandler.getTranslation('energy_intensity_for')} 
  units={usePopulation ? langHandler.getUnits('energy_per_pop') : langHandler.getUnits('energy_per_gdp')}
  chartOffsetLeft={$mq.laptop ? 0 : '3ch'}>
  <span slot="sector-dropdown">
    <SectorDropdown/>
  </span>
  <span slot="chart">
  <!-- Use a yAccessor instead of a key here -->
  {#if processedGHGData}
    <LineChart data={processedGHGData} 
      includesRanges={true}
      xKey={"year"}
      xDomain={[years.historical[0], years.projection[1]]}
      yKey={energyIntensityKey}
      targetValue={$getValue($selectedSector.id, 2030, d => d[energyIntensityKey], "NZP_upper")}
      actualValue={$getValue($selectedSector.id, 2030, d => d[energyIntensityKey], $selectedProjection.id)}
      stroke={chartColours.drivers['efficiency']}
      highlight={{fill: chartColours.drivers['efficiency'], opacity: 0.1}}
      zKey={"scenario"} 
      zDomain={["historical","NZP_upper", "NZP_lower", "NZP_range", ...projectionVars]} 
      tooltipUnits={usePopulation ? langHandler.getUnits('energy_per_pop_short') : langHandler.getUnits('energy_per_gdp_short')}
      tooltipVarName={langHandler.getTranslation('energy_intensity')}
      />
  {:else}
    <div class="loading-text">{langHandler.getTranslation('loading_chart')}</div>
  {/if}
  </span>
  <span slot="legend">
    <ScenarioSelector allowComparison=true bind:comparisonView/>
  </span>
  <span class="source" slot="source">
    Sources: <a target="_blank" rel="noopener noreferrer" href={langHandler.getLink('statscan')}>{langHandler.getTranslation('statscan')}</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.naviusresearch.com/">Navius Research</a>.
  </span>
  <span slot="caption">
    {#if (langHandler.locale) == 'fr'}
      <p>
        Selon le scénario des <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix} du Plan de réduction des émissions, l’intensité énergétique du {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} devrait 
        <strong>{getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear) >0 ? 'augmenter' : 'diminuer'} de {formatIntensityVal(Math.abs(getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear)))}</strong> de {projectionStartYear} à 2030.
      </p>
      <p>
        Pour être en phase avec la trajectoire carboneutre, l’intensité énergétique doit diminuer de {formatPercentageTracker(-1*(targetIntensityChange))} de {projectionStartYear} à 2030. 
        En s’appuyant sur les {$selectedProjection.name?.toLowerCase()}, on prévoit que l’intensité énergétique {actualIntensityChange >0 ? 'augmentera' : 'diminuera'} de {formatPercentageTracker(Math.abs(actualIntensityChange))}, 
        {#if actualIntensityChange > targetIntensityChange} 
          soit <span class="tracker-text" style='color:{chartColours.targets['miss']}'>un manque à gagner de {formatPercentageTracker(Math.abs(actualIntensityChange - targetIntensityChange))} par rapport à la trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          ce qui le met <span class="tracker-text" style='color:{chartColours.targets['meet']}'>sur la voie d'une trajectoire carboneutre</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {:else}
      <p>
        Under the Emissions Reduction Plan’s <strong>{$selectedProjection.name?.toLowerCase()}</strong>{$selectedProjection.suffix}, energy intensity in {@html sanitizeHtml(langHandler.formatSectorSentence($selectedSector))} is expected to
        <strong>{getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear) >0 ? 'increase' : 'decrease'} by {formatIntensityVal(Math.abs(getIntensityValueByYr(2030) - getIntensityValueByYr(projectionStartYear)))}</strong> from {projectionStartYear} to 2030. 
      </p>
      <p>
        To be aligned with a net zero pathway, energy intensity must decrease by {formatPercentageTracker(-1*(targetIntensityChange))} between {projectionStartYear} and 2030. 
        With {$selectedProjection.name?.toLowerCase()}, energy intensity is projected to {actualIntensityChange >0 ? 'increase' : 'decrease'} by {formatPercentageTracker(Math.abs(actualIntensityChange))}, putting it
        {#if actualIntensityChange > targetIntensityChange} 
          <span class="tracker-text" style='color:{chartColours.targets['miss']}'>{formatPercentageTracker(Math.abs(actualIntensityChange - targetIntensityChange))} behind the net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip>
        {:else}
          <span class="tracker-text" style='color:{chartColours.targets['meet']}'>on track with a net zero pathway</span>.<InfoTooltip>{@html sanitizeHtml(langHandler.NZPtooltip)}</InfoTooltip> 
        {/if}
      </p>
    {/if}
  </span>
</ChartSection>

<div class="text-wrapper">
  <div class="centered-text">
    {#if (langHandler.locale) == 'fr'}
      <p>Bien que l’amélioration de l’intensité énergétique puisse contribuer à l’atteinte des objectifs de réduction des émissions, pour bon nombre de secteurs importants, le passage à l’énergie propre et l’adoption de technologies sobres en carbone sont aussi essentiels.</p>
    {:else}
      <p>While improving energy intensity can help with achieving emissions reduction goals, for many key sectors, making the switch to non-emitting energy and implementing low-emitting technologies are also critical.</p>
    {/if}
  </div>
</div>


<BottomNav page="efficiency"/>

<style>
  .report-name, .source a {
    font-style: italic;
  }

  ul li {
    margin-left: 1em;
  }

  .tracker-text {
    font-weight: 600;
  }

  .activity {
    color: var(--orange-medium);
  }

  .efficiency {
    color: var(--blue);
  }

  .cleaner {
    color: var(--turquoise-medium);
  }

  .text-wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  
  .text-wrapper {
    font-family: var(--serif);
  }

  h1, .section-title {
    font-family: var(--condensed);
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3;
    color: var(--neutral);
  }

  .section-title {
    text-align: center;
    margin: 0 auto;
    margin-top: 32px;
  }

  .section-title.fr {
    max-width: 30ch;
  }
  
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .loading-text {
    height: 350px; /* same as chart-container */
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }</style>
