<!--
  @component
  Adds text annotations that get their x and y placement using the `xScale` and `yScale`.
 -->
<script>
  import { getContext } from 'svelte';

  const { xScale, yScale } = getContext('LayerCake');

  /** @type {Array} annotations - A list of annotation objects. */
  // Each annotation object takes the form of {x, y, label}
  export let annotations = [];
  export let classes = "custom";

  /** @type {Function} [getText=d => d.text] - An accessor function to get the field to display. */
  export let getText = d => d.text;

  export let getSuffix = d => d.suffix;
  export let getPrefix = d => d.prefix;
  export let getColour = d => d.colour;

  /** @type {Boolean} [percentRange=false] - If `true` will set the `top` and `left` CSS positions to percentages instead of pixels. */
  export let percentRange = false;

  export let yDefault = 0;
  export let xDefault = 0;

  $: units = percentRange === true ? '%' : 'px';

  $: top = d => (d && d.y ? $yScale(d.y) + (d.dy ? d.dy : 0): yDefault) + units;
  $: left = d => (d && d.x ? $xScale(d.x) + (d.dx ? d.dx : 0) : xDefault) + units; // bypassed when d.xPx exists
  $: width = d => d && d.width ? $xScale(d.x + d.width) - $xScale(d.x) + units : 'max-content'; // bypassed when d.widthPx exists
</script>

<div class="annotations {classes}">
  {#each annotations as d, i}
    <div
      class="annotation align-{d.align ? d.align : 'left'} {d.subclasses ? d.subclasses : ''}"
      data-id="{i}"
      style="top:{top(d)};width:{d.widthPx ? d.widthPx + 'px': width(d)};{d.align == 'right' ? 'right' : 'left'}:{d.xPx ? d.xPx + 'px' : left(d)};">
      {#if getPrefix(d)}
        <span class="suffix">{@html getPrefix(d)}</span>
      {/if}
      <span class="text" style="color: {getColour(d) ? getColour(d) : 'inherit'};">{@html getText(d)}</span>
      {#if getSuffix(d)}
        <span class="suffix">{@html getSuffix(d)}</span>
      {/if}
    </div>
  {/each}
</div>

<style>
  .annotation {
    position: absolute;
  }

  .align-right {
    text-align: right;
  }

  .align-right .text, .align-right .suffix {
    float: right;
  }

  .white-background {
    background: var(--bg-chart);
    border-radius: 8px;
  }

  .years .annotation{
    text-transform: uppercase;
    font-size: 0.8rem;
    text-align: center;
    line-height: 1;
  }
  
  .years.smaller .annotation {
    font-size: 0.65rem;
  }

  .tracker-text .annotation{
    transform: translateY(-50%);
    line-height: 1;
    font-size: 0.9rem;
    /* padding-bottom: 0.5em;
    padding-top: 0.5em; */
  }

  .tracker-text.smaller .annotation{
    font-size: 0.65rem;
  }
  .tracker-text .text{
    font-weight: 600;
  }

  :global(.tracker-text .text strong) {
    font-size: 1.2em;
  }

  .tracker-text .suffix {
    display: block;
    text-transform: uppercase;
    max-width: 12ch;
    line-height: 1;
    font-size: 0.8em;
  }</style>