<!--
  @component
  Creates a key for ordinal scales on `zScale`.
 -->
 <script>
  import { getContext } from 'svelte';

  /** @type {String} [shape='square'] - The shape for each item. Can be 'circle', 'line', or 'square'; */
  export let shape = 'square';

  /** @type {String} [align='start'] - Sets the CSS flexbox justify-content setting for the box as a whole. Can be 'start', 'center' or 'end'. */
  export let align = 'start';

  /** @type {Function|Object} [lookup] - Either a function that takes the value and returns a formatted string, or an object of values. If a given value is not present in a lookup object, it returns the original value. */
  export let lookup = undefined;

  /** @type {Boolean} [capitalize=true] - Capitalize the first character. */
  export let capitalize = true;

  const { zDomain, zScale, data, custom } = getContext('LayerCake');

  function cap (val) {
    return String(val).replace(/^\w/, d => d.toUpperCase());
  }

  function displayName (val) {
    if (lookup) {
      return typeof lookup === 'function' ? lookup(val) : (lookup[val] || val);
    }
    return capitalize === true ? cap(val) : val;
  }
</script>

<style>
  .key {
    display: flex;
    transform: translateY(-100%);
  }
  .key-item {
    margin-right: 14px;
  }
  .chip {
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
    background: var(--background);
  }
  .chip__circle {
    border-radius: 50%;
  }
  .chip__rectangle {
    width: 20px;
    background: none;
    border: 2px solid var(--background);
  }
  .chip__rectangle:before {
    content: '';
    position: absolute;
    top: -2px; 
    left: -2px;
    width: 100%; 
    height: 100%;
    opacity: var(--chip-opacity);
    border: inherit;
    background: var(--background);
  }
  .chip__line:after {
    content: '';
    position: absolute;
    border-width: 3px;
    width: 14px;
    transform: rotate(-45deg);
    transform-origin: 14px 5px;
  }
  .name {
    display: inline;
    font-size: 14px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  }

  .label {
    font-size: 0.8rem;
    line-height: 1.1;
    font-weight: 400;
    font-style: italic;
    color: var(--neutral);
  }</style>

<div class='key' style="justify-content: {align === 'end' ? 'flex-end': align};">

  {#each $data as group}
    {#if $custom.zLookup[group.key] && $custom.zLookup[group.key]['area']}
    <div class='key-item'>
      <div
        class='chip chip__{shape}'
        style='--background: {
          shape === `line` ?
          `linear-gradient(-45deg, #ffffff 40%, ${$zScale(group.key)} 41%, ${$zScale(group.key)} 59%, #ffffff 60%)`
          : $custom.zLookup[group.key]['fill'] || 'black'};'
        style:--chip-opacity={$custom.zLookup[group.key]['opacity'] ? $custom.zLookup[group.key]['opacity'] : 0.8}
      ></div>
      <div class='name label'>{displayName(group.key)}</div>
    </div>
    {/if}
  {/each}
</div>