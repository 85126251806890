<script>
  import mq from "@/stores/mq.js";
  import viewport from "@/stores/viewport.js";
  
  export let yOffset = 16;
  export let width;
  export let stroke;
  
  import Icon from "./assets/Icon.svelte";

  let show, infoWrapper;

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  const padding = 10;
  const maxScrollbarSize = 17;

  $: finalTooltipWidth = width ? width : ($mq.smallMobile ? 200 : 250);

  let xParent, left;

  $: {
    // Block this together so it reacts when $viewport changes
    xParent = (infoWrapper && infoWrapper.getBoundingClientRect() ? infoWrapper.getBoundingClientRect().x : undefined)
    left = clamp(xParent - finalTooltipWidth / 2, 0 + padding, ($viewport.width - maxScrollbarSize) - finalTooltipWidth - padding)
  }


  // $: console.log({left: xParent - finalTooltipWidth/ 2, right: xParent + finalTooltipWidth/2, vw: $viewport.width, adjusted: left + finalTooltipWidth})

  const showTooltip = () => show=true;
  const hideTooltip = () => show=false;
</script>

<span class="info-wrapper" bind:this={infoWrapper} tabindex="0" aria-label="More info" on:mouseenter={showTooltip} on:mouseleave={hideTooltip} on:focus={showTooltip} on:blur={hideTooltip}>
  <span class="info" style="color:{stroke};"><Icon name="help-circle"/></span>
  <div class="popup" role="tooltip" style="width:{finalTooltipWidth}px;left:{left - xParent}px; top:{yOffset}px; display:{show ? 'block' : 'none'};">
    <slot>Lorem ipsum dolor</slot>
  </div>
</span>

<style>
  .info {
    transform: translateY(25%);
    margin-left: 2px;
    /* cursor: pointer; */
  }

  .info-wrapper {
    position: relative; /* For the popup */
  }

  .popup {
    position: absolute;
    padding: 12px;
    /* font-size: 0.8rem; */
    text-align: initial;
    color: var(--default);
    font-style: initial;
    font-size: 0.75rem;
    background-color: #fff;
    border-radius: var(--border-curve);
    border: 1px solid var(--default);
    position: absolute;
    /* text-align: center; */
    z-index: var(--z-middle);
    font-weight: initial;
    text-transform: initial;
    max-width: 70vw;
  }</style>