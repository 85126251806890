<script>
  import mq from "@/stores/mq.js";

  import Toggle from './Toggle.svelte'
  import Dropdown from "./Dropdown.svelte";
  import InfoTooltip from "./InfoTooltip.svelte";
  import {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    RadioGroupDescription,
  } from "@rgossiaux/svelte-headlessui";

  import { selectedProjection, projectionOptions } from '@/stores/interactivity.js'
  import * as langHandler from '@/utils/language-handler.js';
  import { chartColours, lineOpacities } from '@/constants.js'

  export let allowComparison = false;
  export let comparisonView = false;

  $: { // Disable comparisonView once you can't toggle anymore
    if (!$mq.laptop){
      comparisonView = false
    }
  }

  $: isIncludedInProjection = function(d){
    if(comparisonView || d == $selectedProjection.id || d == 'ERP_legislated'){ // included if matches selection. and legislated is always included.
      return true
    } else if(d == 'ERP_developing'){ // announced also includes developing
      return ($selectedProjection.id.includes('ERP_announced')  ? true : false)
    } else if(d == 'ERP_announced_less'){ // announced_more also includes announced_less
      return ($selectedProjection.id == 'ERP_announced_more' ? true : false)
    } else {
      return false
    }
  }
</script>

{#if !$mq.laptop}
  <div class="descriptor">{langHandler.locale == 'fr' ? 'avec la projection du PRE y compris' :  'with ERP projection including'}: 
  <InfoTooltip width={$mq.smallMobile ? 200 : 250}>
    <div class="ERP_info">
      <!-- Exactly the same as below -->
      {#if (langHandler.locale) == 'fr'}
      <p>Dans son évaluation du <strong>Plan de réduction des émissions</strong> (PRE) pour 2030, l’Institut a identifié trois types de politiques:</p>
      <ul>
        <li><em>Légiférées :</em> Politiques inscrites dans la loi et engagements figurant dans les budgets fédéraux.</li>
        <li><em>En élaboration :</em> Politiques en cours d’élaboration avec des documents publics disponibles sur leur conception, leur mise en œuvre, leur rigueur et leur couverture probables.</li>
        <li>
          <em>Annoncées :</em> Engagements politiques avec peu d’informations sur leur conception, leur mise en œuvre, leur couverture et leur rigueur.
          <div class="more-stringent-definition">→ <b>Corriger les interactions entre politiques :</b> Ajuster les interactions entre certaines politiques, notamment entre le plafond d’émissions pétrogazières et les systèmes d’échanges par les grands émetteurs.</div>
        </li>
      </ul>
      {:else}
      <p>In the Institute’s assessment of the 2030 <strong>Emissions Reduction Plan</strong> (ERP), three policy types were identified:</p>
      <ul>
        <li><em>Legislated:</em> Policies that are enshrined in law and commitments that appear in federal budgets.</li>
        <li><em>Developing:</em> Policies in development with public documents available about their likely design, implementation, stringency and coverage.</li>
        <li>
          <em>Announced:</em> Policy commitments with little information about the specific design, implementation, coverage, and stringency.
          <div class="more-stringent-definition">→ <b>Fix policy interactions:</b> Address interactions between some policies, including the oil and gas emissions cap and large emitter trading systems.</div>
        </li>
      </ul>
      {/if}
    </div>
  </InfoTooltip>
  </div>
  <Dropdown options={$projectionOptions} bind:selectedOption={$selectedProjection} />
{:else}
  <div class="selector-container {comparisonView ? 'comparison-view' : 'default-view'} {langHandler.locale}" class:has-compare={allowComparison}>
    <div class="selector-title contains-info">
      {#if (langHandler.locale) == 'fr'}
        Projection du PRE
      {:else}
        ERP Projection
      {/if}
      <InfoTooltip width={250}>
        <div class="ERP_info">
          {#if (langHandler.locale) == 'fr'}
          <p>Dans son évaluation du <strong>Plan de réduction des émissions</strong> (PRE) pour 2030, l’Institut a identifié trois types de politiques:</p>
          <ul>
            <li><em>Légiférées :</em> Politiques inscrites dans la loi et engagements figurant dans les budgets fédéraux.</li>
            <li><em>En élaboration :</em> Politiques en cours d’élaboration avec des documents publics disponibles sur leur conception, leur mise en œuvre, leur rigueur et leur couverture probables.</li>
            <li>
              <em>Annoncées :</em> Engagements politiques avec peu d’informations sur leur conception, leur mise en œuvre, leur couverture et leur rigueur.
              <div class="more-stringent-definition">→ <b>Corriger les interactions entre politiques :</b> Ajuster les interactions entre certaines politiques, notamment entre le plafond d’émissions pétrogazières et les systèmes d’échanges par les grands émetteurs.</div>
            </li>
          </ul>
          {:else}
          <p>In the Institute’s assessment of the 2030 <strong>Emissions Reduction Plan</strong> (ERP), three policy types were identified:</p>
          <ul>
            <li><em>Legislated:</em> Policies that are enshrined in law and commitments that appear in federal budgets.</li>
            <li><em>Developing:</em> Policies in development with public documents available about their likely design, implementation, stringency and coverage.</li>
            <li>
              <em>Announced:</em> Policy commitments with little information about the specific design, implementation, coverage, and stringency.
              <div class="more-stringent-definition">→ <b>Fix policy interactions:</b> Address interactions between some policies, including the oil and gas emissions cap and large emitter trading systems.</div>
            </li>
          </ul>
          {/if}
        </div>
      </InfoTooltip>
    </div>
    <RadioGroup class="scenario-selector" value={$selectedProjection.id} on:change={(e) => {comparisonView = false; selectedProjection.set($projectionOptions.find(d => d.id == e.detail));}}>
      <span class="selector-subtitle"><RadioGroupLabel>{comparisonView ? langHandler.getTranslation('policy_scenarios') : langHandler.getTranslation('click_to_include_policies')}:</RadioGroupLabel></span>
      <div class="selector-options">
      <div class="line"/>
      <div class="line extra-option"/>

      <div class="option" on:click={(e) => {comparisonView = false;}}   style="--line-colour: {chartColours.scenarios['ERP_legislated']} ;--line-opacity: {lineOpacities.scenarios['ERP_legislated']};">
        <RadioGroupOption value="ERP_legislated" let:checked>
        <span class="option-text" class:checked class:highlighted={isIncludedInProjection("ERP_legislated")}>{langHandler.getTranslation('legislated')}</span>
        </RadioGroupOption>
        <svg class="option-symbol" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="35px">
          <line class="legend-line ERP_legislated" x1="0" y1="15" x2="28" y2="15" stroke="currentColor" stroke-width="2"/>
          <circle class="legend-dot" cx="28" cy="15" r="5" stroke="currentColor"/>
        </svg>       
      </div>

      <div class="option" on:click={(e) => {comparisonView = false;}}   style="--line-colour: {chartColours.scenarios['ERP_developing']};--line-opacity: {lineOpacities.scenarios['ERP_developing']};">
        <RadioGroupOption value="ERP_developing" let:checked>
          <span class="option-text" class:checked class:highlighted={isIncludedInProjection("ERP_developing")}>{comparisonView ? langHandler.getTranslation('ERP_developing_short') : langHandler.getTranslation('developing')}</span>
        </RadioGroupOption>
        <svg class="option-symbol" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="35px">
          <line class="legend-line ERP_developing" x1="0" y1="15" x2="28" y2="15" stroke="currentColor" stroke-width="2"/>
          <circle class="legend-dot" cx="28" cy="15" r="5" stroke="currentColor"/>
        </svg> 
      </div>
      
      <div class="option" on:click={(e) => {comparisonView = false;}}   style="--line-colour: {chartColours.scenarios['ERP_announced_less']};--line-opacity: {lineOpacities.scenarios['ERP_announced_less']};">
        <RadioGroupOption value="ERP_announced_less" let:checked> <!-- disabled={comparisonView} -->
          <span class="option-text" class:checked class:highlighted={isIncludedInProjection("ERP_announced_less")}>{comparisonView ? langHandler.getTranslation('ERP_announced_short') : langHandler.getTranslation('announced')}</span>
        </RadioGroupOption>
        <svg class="option-symbol" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="35px">
          <line class="legend-line ERP_announced_less" x1="0" y1="15" x2="28" y2="15" stroke="currentColor" stroke-width="2"/>
          <circle class="legend-dot" cx="28" cy="15" r="5" stroke="currentColor"/>
        </svg>       
      </div>

      <div class="option extra-option" on:click={(e) => {comparisonView = false;}}  style="--line-colour: {chartColours.scenarios['ERP_announced_more']};--line-opacity: {lineOpacities.scenarios['ERP_announced_more']};">
        <RadioGroupOption value="ERP_announced_more" let:checked> <!-- disabled={comparisonView} -->
          <span class="option-text" class:checked class:highlighted={isIncludedInProjection("ERP_announced_more")}>{comparisonView ? langHandler.getTranslation('ERP_announced_more_short') : langHandler.getTranslation('more_improvements')}</span>
        </RadioGroupOption>
        <svg class="option-symbol" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="35px">
          <line class="legend-line ERP_announced_more" x1="0" y1="15" x2="28" y2="15" stroke="currentColor" stroke-width="2"/>
          <!-- <circle class="legend-dot" cx="28" cy="15" r="5" stroke="currentColor"/> -->
        </svg>       
      </div>
      </div>
    </RadioGroup>

    {#if allowComparison}
      <div class="comparison-toggle">
        <Toggle bind:enabled={comparisonView} label={langHandler.getTranslation('comparison_view')}/>
      </div>
    {/if}
  </div>

{/if}

<style>

  .ERP_info {
    text-align: left;
    font-size: 0.75rem;
  }
  .ERP_info em {
    font-style: italic;
    font-weight: 600;
  }
  .ERP_info p {
    margin-top: 0;
    margin-bottom: 0.25em;
  }

  .ERP_info li {
    margin-left: 1em;
  }

  .ERP_info li p {
    margin-top: 0.5em;
  }

  .descriptor {
    margin-top: 8px;
    margin-bottom: 4px;
    padding-left: 4px;
    font-size: 0.825rem;
  }
  .selector-container {
    --margin-before-circles: 3px;
    --margin-between: 0.4rem;
    --circle-width: 0.875rem;
    --text-margin-left: calc(var(--circle-width) + var(--margin-before-circles) + 0.5rem);
    background-color: var(--bg);
    border-radius: var(--border-curve);
    padding: 10px;
    padding-right: 6px;
    font-size: 0.75rem;
    color: var(--default);
    position: relative;
    border: solid 1px white;
  }

  .comparison-view.selector-container {
    background: var(--neutral-faded2);
    border: solid 1px var(--neutral-faded1);
  }
  .legend-dot {
    /* fill: var(--bg);
    color: var(--neutral); */
    display: none;
  }
  .legend-line {
    color: var(--neutral);
    color: var(--line-colour);
    opacity: var(--line-opacity);
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .ERP_legislated{
    stroke-dasharray: var(--dash_legislated);
  }

  .ERP_developing {
    stroke-dasharray: var(--dash_developing);
  }

  .ERP_announced {
    stroke-dasharray: var(--dash_announced);
  }
  .ERP_announced_more {
    stroke-dasharray: var(--dash_announced_more);
  }
  .ERP_announced_less {
    stroke-dasharray: var(--dash_announced_less);
  }
  .selector-options {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    position: relative;
  }

  .has-compare.selector-container {
    min-height: 14.5rem;
  }
  .has-compare.selector-container.fr {
    min-height: 17.5rem;
  }

  .has-compare.default-view .selector-options {
    margin-top: 6px;
  }

  .option { 
    /* need this to have the :before elements on top of the line (create new stacking context) */
    position: relative;
    z-index: 1;
    margin-top: var(--margin-between);
    margin-bottom: var(--margin-between);
  }

  .default-view .line {
    position: absolute; 
    left: calc(var(--margin-before-circles, 3px) + (var(--circle-width,14px)/2));
    width: calc(var(--margin-before-circles, 3px) + (var(--circle-width,14px)/2) + 2px);
    top: calc(var(--circle-width,14px)/2);
    height: calc(var(--circle-width,14px)*3 + var(--margin-between,4px)*2);
    border-left: 1.5px solid var(--neutral);
  }

  .default-view .line.extra-option {
    top: calc(var(--circle-width,14px)*3.5 + var(--margin-between,4px)*2);
    height: calc(var(--circle-width) + calc(var(--margin-between) * 1.5) + 2px);
    border-left: 1.5px solid hsl(189, 15%, 70%);
    border-bottom: 1.5px solid hsl(189, 15%, 70%);
  }

  .default-view .option.extra-option {
    margin-top: calc(var(--margin-between) * 1.5);
    margin-left: var(--circle-width);
  }

  .option-text {
    cursor: pointer;
    font-weight: 450;
    display: inline-block;
    position: relative;
  }

  .default-view .option-text {
    margin-left: var(--text-margin-left);
  }


  .default-view .option-text::before {   /* .option-text::before { */
    display: inline-block;
    position: absolute;
    left: calc(var(--margin-before-circles) - var(--text-margin-left));
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='none' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'><line x1='12' y1='5' x2='12' y2='19'></line><line x1='5' y1='12' x2='19' y2='12'></line></svg>");
    background-color: #FFF;
    border: 1.5px solid var(--neutral);
    border: 1.5px solid var(--line-colour);
    border-radius: 50%;
    width: var(--circle-width);
    height: var(--circle-width);
    transform: translateY(calc(var(--margin-between) /2));
    text-align: center;
  }

  .default-view .extra-option .option-text::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23a7bbbe' opacity='0.7' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus'><line x1='12' y1='5' x2='12' y2='19'></line><line x1='5' y1='12' x2='19' y2='12'></line></svg>");
  }

  .default-view .option-text.highlighted::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 28' fill='none' stroke='%23004744' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
    background-color: var(--neutral-muted);
    border: 1.5px solid var(--neutral);
  }

  .default-view .option-text.checked::before {
    background-color: var(--neutral);
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 28' fill='none' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  }

  .default-view .option-text.checked::before,  .default-view .comparison-view .option-text.highlighted::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 28' fill='none' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>");
  }

  .comparison-view .option-text {
    line-height: 1.3;
    /* color: var(--line-colour); */
  }

  .more-stringent-definition { 
    margin-top: 0.25em;
  }

  .comparison-view .option-text.highlighted::before {
    background-color: var(--neutral-muted);
    border-color: var(--neutral-muted);
  } 

  .option-symbol {  /* This is the legend, hide by default */
    display: none;
    position: absolute;
    left: var(--margin-before-circles);
    top: 1em;
    transform: translateY(-50%);
  }
  
  .comparison-view .option-symbol {
    display: inline-block;
  }

  .comparison-view .line {
    border-left-color: var(--neutral-muted);
  }


  .default-view .option-text.checked, .default-view .option:hover .option-text{
    font-weight: 700;
    color: var(--neutral);
    /* cursor: initial; */
  }

  .comparison-view .option:hover .option-text{
    font-weight: 500;
  }

  .comparison-view .option-text {
    font-size: 0.75rem;
    /* font-style: italic; */
    font-weight: 400;
    margin-left: calc(35px + var(--margin-before-circles, 3px) + 3px);
  }

  .comparison-toggle {
    position: absolute;
    bottom: 10px;
    display: inline-block;
    margin-top: 6px;
    padding-top: 10px;
    margin-left: var(--margin-before-circles);
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-style: italic;
    border-top: 1px solid var(--neutral-faded1);
  }

  .selector-title {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 6px;
    color: var(--neutral);
  }

  /* .comparison-view .selector-subtitle {
    color: var(--neutral-muted);
  } */
  .selector-subtitle, .comparison-toggle {
    font-size: 0.75rem;
  }</style>
