<script>
  import { getContext } from 'svelte';
  import { textWrap } from '@/utils/textwrap.js';
  const { width, height, xScale, yRange, padding, custom } = getContext('LayerCake');

  export let gridlines = true;
  export let tickMarks = true;
  export let formatTick = (d) => d;
  export let baseline = false;
  export let snapTicks = false;
  export let ticks = undefined;
  export let xTick = undefined;
  export let yTick = 16;
  export let dxTick = 0;
  export let dyTick = 0;

  export let positionAbove = false;
  export let positionOffset = 0;
  export let wrapText = false;
  export let highlightFirstLast = false;
  export let boldTheseTicks = [];

  $: smaller = $custom.smallerAxisText ? $custom.smallerAxisText : false;

  $: yTranslate = positionOffset + (positionAbove ? 0 : $yRange[0]);

  $: isBandwidth = typeof $xScale.bandwidth === 'function';

  $: tickVals = Array.isArray(ticks)
    ? ticks
    : isBandwidth
    ? $xScale.domain()
    : typeof ticks === 'function'
    ? ticks($xScale.ticks())
    : $xScale.ticks(ticks);

  function textAnchor(i) {
    if (snapTicks === true) {
      if (i === 0) {
        return 'start';
      }
      if (i === tickVals.length - 1) {
        return 'end';
      }
    }
    return 'middle';
  }
</script>

<g class="axis x-axis" class:snapTicks>
  {#each tickVals as tick, i}
    {#if typeof $xScale(tick) !== 'undefined'}
      <g
        class="tick tick-{i}"
        transform="translate({$xScale(tick)},{yTranslate})">
        {#if gridlines !== false}
          <line
            class="gridline"
            y1={$height * (positionAbove ? 1 : -1)}
            y2="0"
            x1="0"
            x2="0" />
        {/if}
        {#if tickMarks === true}
          <line
            class="tick-mark"
            y1={0}
            y2={6 *
              (positionAbove
                ? -1
                : highlightFirstLast && (i == 0 || i == tickVals.length - 1)
                ? 4
                : 1)}
            x1={xTick || isBandwidth ? $xScale.bandwidth() / 2 : 0}
            x2={xTick || isBandwidth ? $xScale.bandwidth() / 2 : 0} />
        {/if}
        <text
          x={xTick || isBandwidth ? $xScale.bandwidth() / 2 : 0}
          y={yTick * (positionAbove ? -1 : 1)}
          dx={dxTick}
          dy={dyTick}
          class={(boldTheseTicks.indexOf(+tick) > -1 ? "bold" : "") + " " + (smaller ? "smaller" : "")}
          text-anchor={textAnchor(i)}>
          {#if wrapText}
            {#each textWrap(String(formatTick(tick))) as line}<tspan
                x={isBandwidth ? $xScale.bandwidth() / 2 : dxTick}
                y={line.y + 16}
                dy={highlightFirstLast && (i == 0 || i == tickVals.length - 1)
                  ? 24
                  : '.35em'}>{line.label}</tspan
              >{/each}
          {:else}
            {formatTick(tick)}
          {/if}
        </text>
      </g>
    {/if}
  {/each}
  {#if baseline === true}
    <line
      class="baseline"
      y1={(positionAbove ? yTranslate : $height) + 0.5}
      y2={(positionAbove ? yTranslate : $height) + 0.5}
      x1="0"
      x2={$width} />
  {/if}
</g>

<style>
  .tick {
    /* font-size: 0.725em; */
    font-size: 0.875rem;
    font-weight: 200;
  }

  line,
  .tick line {
    stroke: var(--axes);
    /* stroke: #aaa; */
    stroke-dasharray: 2;
  }

  .tick text {
    fill: var(--text-axis);
    /* fill: #666; */
  }

  .tick text.bold {
    font-weight: 600;
  }

  .tick text.smaller {
    font-size: 0.75rem;
  }

  .tick .tick-mark,
  .baseline {
    stroke-dasharray: 0;
  }
  /* This looks slightly better */
  .axis.snapTicks .tick:last-child text {
    transform: translateX(3px);
  }
  .axis.snapTicks .tick.tick-0 text {
    transform: translateX(-3px);
  }</style>
