<!--
  @component
  Generates HTML text labels for a nested data structure. It places the label near the y-value of the highest x-valued data point. This is useful for labeling the final point in a multi-series line chart, for example. It expects your data to be an array of objects where each has `values` field that is an array of data objects. It uses the `z` field accessor to pull the text label.
 -->
<script>
  import { getContext } from 'svelte';
  import { max, mean, min } from 'd3-array';
    import { group } from 'd3';

  const { data, x, xGet, y, yGet, xScale, yScale, xRange, yRange, z } = getContext('LayerCake');

  export let formatKey = d => d;
  export let paddingX = 10;
  export let paddingY = 10;
  export let opacity = 1;
  export let xDiffForAngle = 2;

  // const angleCalcOffset = 10; // years
  // const getLastValue = values => values.find(d => $x(d) == max(values, $x))
  // const getBeforeLastValue = values => values.find(d => $x(d) == (max(values, $x) - angleCalcOffset)) // assumes data is availalable

  const getLastValue = values => values[values.length - 1]
  $: getBeforeLastValue = values => values[Math.max(values.length - 1 - xDiffForAngle, 0)]


  $: yDiff = values => (bottom(getLastValue(values)) - bottom(getBeforeLastValue(values)))
  $: xDiff = values => $xGet(getLastValue(values)) - $xGet(getBeforeLastValue(values))

  $: getAngle = values => Math.atan2(yDiff(values),xDiff(values)) * 180 / Math.PI * -1;
  

  // $: left = values => $xScale(max(values, $x));
  // $: top = values => Array.isArray($y(values)) ? $yScale(mean($y(values))) : $yGet(values);
  $: right = d => $xRange[1] - $xGet(d);
  $: bottom = d => $yRange[0] - (Array.isArray($y(d)) ? $yScale(mean($y(d))) : $yGet(d));

  //         bottom:{Math.max($yRange[1], bottom(getLastValue(group.values))) - yDiff(group.values)*0.25}px;
  //         bottom:{bottom(getLastValue(group.values))}px;
</script>

{#each $data as group}
  {#if formatKey(group.key) !== ""}
    <div
      class="group-label"
      style="
        bottom:{Math.max($yRange[1], bottom(getLastValue(group.values))) - yDiff(group.values)*0.25}px;
        right:{right(getLastValue(group.values)) + paddingX}px;
        transform:rotate({getAngle(group.values)}deg);
        opacity:{opacity};
      "
    >{formatKey(group.key)}</div>
  {/if}
{/each}

<style>
  .group-label {
    position: absolute;
    /* transform: translate(-50%, -50%); */
    font-size: 0.8rem;
    line-height: 1.1;
    font-weight: 400;
    font-style: italic;
    color: var(--neutral);
    max-width: 30%;
    text-align: right;
  }</style>