<script>
  import { getContext } from 'svelte';
  import { textWrap } from '@/utils/textwrap.js';
  const { width, padding, xRange, yScale, xScale, custom } = getContext('LayerCake');

  export let ticks = 4;
  export let tickMarks = false;
  export let gridlines = true;
  export let formatTick = (d) => d;
  export let xTick = 0;
  export let yTick = 0;
  export let dxTick = 0;
  export let dyTick = -4;
  export let textAnchor = 'start';
  export let wrapText = false;
  export let wrapTextLimit = 25;
  export let removeFirstTick = false;
  export let boldTheseTicks = [];
  
  $: isBandwidth = typeof $yScale.bandwidth === 'function';

  $: tickVals = Array.isArray(ticks)
    ? ticks
    : isBandwidth
    ? $yScale.domain()
    : typeof ticks === 'function'
    ? ticks($yScale.ticks())
    : $yScale.ticks(ticks);

  $: smaller = $custom.smallerAxisText ? $custom.smallerAxisText : false;
</script>

<g class="axis y-axis" transform="translate({-$padding.left}, 0)">
  {#each tickVals as tick, i}
    <g
      class="tick tick-{tick}"
      transform="translate({$xRange[0] +
        (isBandwidth ? $padding.left : 0)}, {$yScale(tick)})">
      {#if gridlines !== false && $yScale(tick) > 0}
        <line
          class="gridline"
          x2={$width}
          y1={yTick + (isBandwidth ? $yScale.bandwidth() / 2 : 0)}
          y2={yTick + (isBandwidth ? $yScale.bandwidth() / 2 : 0)} />
      {/if}
      {#if tickMarks === true && (!removeFirstTick || i > 0)}
        <line
          class="tick-mark"
          x1="0"
          x2={isBandwidth ? -6 : 6}
          y1={yTick + (isBandwidth ? $yScale.bandwidth() / 2 : 0)}
          y2={yTick + (isBandwidth ? $yScale.bandwidth() / 2 : 0)} />
      {/if}
      <text
        x={xTick}
        y={yTick + (isBandwidth ? $yScale.bandwidth() / 2 : 0)}
        dx={isBandwidth ? -9 : dxTick}
        dy={isBandwidth ? 4 : dyTick}
        class={(boldTheseTicks.indexOf(+tick) > -1 ? "bold" : "")  + (smaller ? "smaller" : "")}
        style="text-anchor:{isBandwidth ? 'end' : textAnchor};">
        {#if wrapText}
          {#each textWrap(String(formatTick(tick)), wrapTextLimit) as line}<tspan
              x={(isBandwidth ? -9 : dxTick) - 5}
              y={(isBandwidth ? $yScale.bandwidth() / 2 : 0) + line.y}
              dy=".35em"
              dx=".3em">{line.label}</tspan
            >{/each}
        {:else}
          {formatTick(tick)}
        {/if}
      </text>
    </g>
  {/each}
</g>

<style>
  .tick {
    /* font-size: 0.725em; */
    font-size: 0.75rem;
    font-weight: 200;
  }

  .tick line {
    stroke: #aaa;
  }
  .tick .gridline {
    stroke-dasharray: 2;
  }

  .tick text {
    fill: var(--text-axis);
  }

  .tick text.smaller {
    font-size: 0.7rem;
  }

  .tick text.bold {
    font-weight: 600;
  }

  .tick.tick-0 line {
    stroke-dasharray: 0;
  }</style>
