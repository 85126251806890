 <script>
  import { getContext } from 'svelte';
  import { format } from 'd3-format';

  import mq from "@/stores/mq.js";

  const { data, xGet, y, r, x, yScale, zScale, custom } = getContext('LayerCake');

  export let getNames = d => d;
  const formatLabel = d => Math.abs(d) < 100 ? format('+.2g')(d) : format('+d')(d)

  $: arrowUp = d => ($r(d) - $y(d)) > 0
  $: yAdjust = d => 8 * (arrowUp(d) ? -1 : 1) // in html, - is down


</script>

{#each $data as group}
<!-- width:max-content is important here to avoid overflow -->
<div class="label-group" style="transform: translate({$zScale(group.key)}px, 0);width: max-content;">
  {#each group.values as d, i}
  <div
    class="value label"
    class:smaller={$mq.mobile} 
    style="
      top:{$yScale($r(d)) + yAdjust(d)}px;
      left:{$xGet(d)}px;
      background:{$custom.getBackgroundFills(group.key)};
    ">
    <!-- Can also access this with d[$custom.yKey] now-->
    {formatLabel($r(d) - $y(d))}
  </div> 
  <div class="driver label"
    style="
    top:{$yScale($r(d)) - (yAdjust(d) * 1.4)}px;
    left:{$xGet(d)}px;">
    {getNames($x(d))[0]}
  </div>
  {/each}
</div>
{/each}


<style>
  .label {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 0.725rem;
    line-height: 1;
  }

  .label.smaller {
    font-size: 0.625rem;
  }

  .driver.label {
    font-size: 0.6em;
    color: #FFF;
    text-transform: uppercase;
  }</style>

