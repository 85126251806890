<script>
  import mq from "@/stores/mq.js";

  import { LayerCake, Svg, Html } from 'layercake';
  import { format } from 'd3-format';
  import { group, groups, range, max } from 'd3-array';
  import { scaleLinear, scaleOrdinal } from 'd3-scale';
  import css from '@/utils/css.js'
  import { chartColours, lineOpacities, years } from '@/constants.js';
  import { formatDefaultVal, formatDefaultAxis } from "@/utils/dataHelpers.js"
  import * as langHandler from '@/utils/language-handler.js';

  import Line from './chart_parts/Line.svelte';
  import MultiLine from './chart_parts/MultiLine.svelte';
  import AreaBetween from './chart_parts/AreaBetween.svelte';
  import RectangleHighlight from './chart_parts/RectangleHighlight.svelte';
  import MultiDot from './chart_parts/MultiDot.svelte'
  import SharedTooltip from './chart_parts/SharedTooltipCustomized_html.svelte';
  import GroupLabels from './chart_parts/GroupLabels_html.svelte';
  import Annotations from './chart_parts/AnnotationsCustom_html.svelte';
  import Bracket from './chart_parts/Bracket.svelte'
  import AreaLegend from "./chart_parts/AreaLegend.svelte";

  // import Labels from './chart_parts/Labels.svelte';

  import AxisX from './chart_parts/AxisX.svelte';
  import AxisY from './chart_parts/AxisY.svelte';

  export let yAxisLabelOffset = -4;
  export let topPadding = 50;
  export let padding = { top: topPadding, bottom: 20, right: 0, left:  - yAxisLabelOffset}; 
  // export let showLabels = true;

  export let data;

  export let stroke = '#000'; //"#3c5c89";
  export let highlight = {fill: '#CCC', opacity: 1};
  export let line = false;
  
  export let xKey = 'year';
  export let yKey = 'value';
  export let zKey = 'variable';

  export let yAccessor = d => d[yKey];

  // export let yDomain = [0, null];
  export let xDomain = [years.historical[0], years.projection[1]] // null // [0, null]
  export let zDomain;

  export let includesRanges = false;
  export let targetValue;
  export let actualValue;

  export let tooltipUnits = '';
  export let tooltipVarName = langHandler.getProjectionName('historical');
  export let formatAxisVal = formatDefaultAxis; 
  export let formatTooltipVal = formatDefaultVal;

  export let formatSpecial_NZP = undefined; // include {fill, opacity, label}

  $: formatTooltipValue = d => (isNaN(+d) ?  `${formatTooltipVal(d[0])} to ${formatTooltipVal(d[1])}` : formatTooltipVal(d)) + " " + tooltipUnits;

  export let showAnnotations = false;

  let dataForChart = data;

  const projectionStartYear = Number(years.projection[0])

  // Have consistent yDomain across all scenarios (data that includes all scenarios but is plotted in the xDomain)
  $: yDomain = [0, max(data.filter(d => d[xKey] <= xDomain[1] && d[xKey] >= xDomain[0]).map(d => d[yKey]).filter(d => d != null))] 


  $:{ 
    // Make sure our data is formatted as numbers (unless it's null or undefined, or an array)
    data.forEach(row => {
      row[yKey] = yAccessor(row) == null || Array.isArray(yAccessor(row)) ? yAccessor(row) : +yAccessor(row);
    });
    // Further filter data for chart (only take those in zDomain, and don't plot null values)
    dataForChart = data.filter(d => (zDomain.indexOf(d[zKey]) != -1 && d[yKey] !== null && d[xKey] <= xDomain[1] && d[xKey] >= xDomain[0]));
  }


  let zLookup = {};

  if (zKey){
    for (let val in chartColours.scenarios){
      let area = val.includes("range") // this is where we add an "AreaBetween" section for anything with a range for values (net zero)
      if (formatSpecial_NZP && val.includes("NZP")){
        zLookup[val] = {
          stroke: area ? "none" : formatSpecial_NZP.fill, //chartColours.scenarios[val], //formatSpecial_NZP.fill, 
          strokeWidth: 2.5,
          fill: formatSpecial_NZP.fill, //chartColours.scenarios[val], //formatSpecial_NZP.fill, 
          class: "",
          area: area,
          opacity: area ? formatSpecial_NZP.opacity : 0 //0.4
        };
      } else {
        zLookup[val] = {
          stroke: area ? "none" : (val == "historical" ? stroke : chartColours.scenarios[val]),
          strokeWidth: val.includes("ERP") ? 1.75 : 2.5,
          fill: area ? chartColours.scenarios[val] : "none", 
          class: val.includes("ERP") ? "dashed" + " " + val : "",
          area: area,
          opacity: val.includes("NZP") ? 0.5 : (lineOpacities?.scenarios[val] || 1)
        };
      }
    }
  } 

  const groupData = (dataset) => groups(dataset, d => d[zKey]).map(d => ({key: d[0], values: d[1]}))
  const orderGroupedData = (dataset, orderArray) => orderArray.map(g => dataset.filter(d => d.key === g)[0]) // Reorder by zDomain entries

  // For tooltips: Create a dataset where columns are scenarios and rows are yKey value (e.g. "ghg"). 
  const castToWide = function(data){
    // group the data
    const grouped = group(
      data,
      d => d[xKey],
      d => d[zKey]
    );
    // create a wide data set (output array)
    const data_wide = [];
    grouped.forEach((innerMap, xVal) => {
      // create a 'row' with that y value (use yKey as the key)
      const row = {
        [xKey]: xVal
      };
      // add properties to the 'row' for each variable that exists in that data
      zDomain.forEach(col => row[col] = innerMap.has(col) ? innerMap.get(col)[0][yKey] : 0);
      // store the result
      data_wide.push(row);
    });
    return data_wide
  }

  // Also create a function that determines whether or not to display that key
  const displayOnTooltip = (d, year) => {
    if (year > years.netZero[0]){
      return (d == "NZP_range") ? true : false;
    } else if (year >= years.projection[0]){
      return (d.includes("ERP") || d == "NZP_range") ? true : false;
    } else {
      return d == "historical" ? true : false;
    }
  }

  $: formatTooltipKey = function(d){
    if (d == 'historical'){
      return tooltipVarName ;
    } else if (d == 'ERP_legislated' || d.includes('ERP') && (zDomain.filter(d => d.includes("ERP")).length == 1)){
      return `${tooltipVarName} ${langHandler.getTranslation('under')} ${langHandler.getProjectionName(d)}`; // definingERP[d]; // 
    } else {
      return langHandler.getProjectionName(d);
    }
  }
  
  // For the dot highlights. Instead of a lookup, we'll have functions because there are a lot of dot here and we can compute those on the fly
  // Also, the criteria is pretty scattered and isn't linked to a single key (i.e. year AND value)
  $: getDotFormat = d => {
    let fill, opacity;
    let stroke = chartColours.neutrals['line'];
    if (d.year == years.projection[0]){
      fill = chartColours.neutrals['line'];
      opacity = 1;
    } else if (d.year == years.projection[1] && !d.scenario.includes("NZP")){
      opacity = 0.8;
      if (targetValue == null){
        fill = chartColours.neutrals['line'];
      } else if(yAccessor(d) <= targetValue){
        fill = chartColours.targets['meet']
      } else {
        fill = chartColours.targets['miss']
      }
    } else {
      fill = 'none';
      opacity = 0;
      stroke = 'none';
    }
    return ({fill, opacity, stroke});
  }
</script>

<div class="chart-container">
    <LayerCake
      data={zDomain ? orderGroupedData(groupData(dataForChart), zDomain) : groupData(dataForChart)}
      flatData={dataForChart}
      padding={padding}
      x={xKey}
      y={yKey}
      z={zKey}
      yScale= {scaleLinear()}
      zScale={scaleOrdinal()}
      yDomain={yDomain}
      xDomain={xDomain}
      custom={{zLookup, getDotFormat, smallerAxisText: $mq.mobile}}
      yNice={true}
    >
      <Svg>
        {#if zKey} 
          <RectangleHighlight x0={years.projection[0]} x1={years.projection[1]} 
            fill={highlight.fill} opacity={highlight.opacity} linePlacement={line ? 'right' : 'none'} lineStroke={line ? stroke : null}/>
            {#if includesRanges}
              <AreaBetween />
            {/if}
          <MultiLine {stroke}/>
          <MultiDot r={4} scaled={false}, opacity={0.8} strokeWidth={1.1}/>
          {#if showAnnotations && actualValue > targetValue}
            <Bracket x={years.projection[1]} y1={targetValue} y2={actualValue} stroke={actualValue > targetValue ?  chartColours.targets['miss'] : chartColours.targets['meet']}/>
          {/if}
        {:else}
          <Line {stroke} strokeWidth={2}/>
        {/if}
        <AxisX gridlines={false} formatTick={d => ""} ticks={range(xDomain[0], xDomain[1], 5)}/>
        <AxisX gridlines={false} snapTicks={true} baseline={true} ticks={xDomain[1] == 2050 ? [2005, projectionStartYear, 2030, 2050] : [2005, projectionStartYear, 2030]} boldTheseTicks={[projectionStartYear, 2030]} dyTick={4}/>
        <AxisY textAnchor={'end'} formatTick={formatAxisVal} tickMarks={true} dyTick={4} dxTick={yAxisLabelOffset} gridlines={false}  removeFirstTick={true}/>
        <!-- <AxisY gridlines={true} formatTick={d => ""} ticks={range(0, 1000, 100)}/> -->
      </Svg>

      <Html>
        {#if includesRanges}
        <!-- Label for NZP area -->
          {#if $mq.useLegendLabels}
            <AreaLegend
              align='end'
              shape='rectangle'
              lookup={d => d == "NZP_range" ? (formatSpecial_NZP?.label ? formatSpecial_NZP.label : langHandler.getProjectionName(d)) : ''}
            />
          {:else}
            <GroupLabels opacity={formatSpecial_NZP ? 0.5 : 1} formatKey={d => d == "NZP_range" ? (formatSpecial_NZP?.label ? formatSpecial_NZP.label : langHandler.getProjectionName(d)) : ''}
              xDiffForAngle={langHandler.locale == 'fr' ? 3 : 2}/>
          {/if} 
        {/if}
        <Annotations classes="years {$mq.mobile ? 'smaller' : ''}" annotations={[
          {x: years.historical[0], text: langHandler.getTranslation('historical'), width: years.historical[1] - years.historical[0]}, 
          {x: years.projection[0], text: langHandler.getTranslation('projected'), width: years.projection[1] - years.projection[0]}]} 
           yDefault={-topPadding*0.8}/>

        {#if targetValue != null && showAnnotations}
          <Annotations classes="tracker-text {actualValue > targetValue ? 'miss' : 'meet'}" annotations={[
            {
              x: years.projection[1], y: actualValue > targetValue ? (actualValue + targetValue)/2 : actualValue, dx: 25,
              text: actualValue > targetValue ? `<strong>${formatDefaultVal(actualValue - targetValue)}</strong> Mt` : langHandler.getTranslation('aligned')?.toUpperCase(), 
              suffix: actualValue > targetValue ?  langHandler.getTranslation('gap_to_get_on_track') :  langHandler.getTranslation('with_net_zero'),
              colour: actualValue > targetValue ?  chartColours.targets['miss'] : chartColours.targets['meet']
            }
          ]}/>
        {/if}
        <SharedTooltip
          dataset={castToWide(dataForChart)} 
          displayKey={displayOnTooltip}
          formatKey={formatTooltipKey}
          formatValue={formatTooltipValue}
        />
      </Html>
    </LayerCake>
</div>


<style>
  /*
    The wrapper div needs to have an explicit width and height in CSS.
    It can also be a flexbox child or CSS grid element.
    The point being it needs dimensions since the <LayerCake> element will
    expand to fill it.
  */
  .chart-container {
    width: 100%;
    height: 350px;
    min-width: 250px;
  }</style>


