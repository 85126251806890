<script>
  // arrows and linecaps
  //http://thenewcode.com/1068/Making-Arrows-in-SVG
  // https://stackoverflow.com/questions/46593502/svg-stroke-linecap-at-one-end-only

  // two x scales, one for year group (2005-2021 vs 2021-2030) and then effect group
  // https://svelte.dev/repl/d5d3eef9aa9a42e4b07c0a3b917a3242?version=3.17.3
  // https://d3-graph-gallery.com/graph/barplot_grouped_basicWide.html

  // Import the getContext, createEventDispatcher function from svelte
  import { getContext, createEventDispatcher } from 'svelte';
  import { raise } from 'layercake';

  export let stroke = '#333';
  export let strokeWidth = 20;

  export let triangleBase = 25;
  export let triangleHeight = 15;
  
  const rectRatio = 0.5;


  // Access the context using the 'LayerCake' keyword
  // Grab some helpful functions

  // Using r as the second y dimension
  const { data, xGet, yGet, x, y, r, xScale, yScale, zScale, custom, xRange, height, padding } = getContext('LayerCake');

  let arrowWidth = strokeWidth
  if ($custom.arrowWidth){
    arrowWidth = $custom.arrowWidth
  }


  // Catch when the height of the triangle is larger than the difference between y1 and y2
  // y1 = start point, y2 = end point of the arrow
  // If r > y, then y2 is the one on the top which means the arrow is going up
  // Because the markers are added to the *end* of the lines, some adjustments are needed:
  // If the arrow is going up, then you want to bring y2 down (+) and y1 up (-)
  // If the arrow is going down, then you want to bring y2 up (-) and y1 down (+)
  // Note: y goes from 0 to + downward

  // TO DO: can the triangleHeight shrink if (lengthInPixels(d) > (triangleHeight + arrowWidth*rectRatio)) ?

  $: lengthInPixels = d => Math.abs($yScale($r(d)) -  $yScale($y(d)))
  $: arrowUp = d => ($r(d) - $y(d)) > 0
  $: y1Adjust = d => arrowWidth*rectRatio * (arrowUp(d) ? -1 : 1) // round end cap
  $: y2Adjust = d => triangleHeight * (arrowUp(d) ? 1 : -1) // triangle

  $: y1Get = d => (lengthInPixels(d) > (triangleHeight + arrowWidth*rectRatio)) ? ($yScale($y(d)) + y1Adjust(d)) : $yScale($r(d)) + y2Adjust(d) + (arrowUp(d) ? 0.001 : -0.001)
  $: y2Get = d => ($yScale($r(d)) + y2Adjust(d))

  // Introduce mouse handler for tooltips, from https://layercake.graphics/example/MapSvg/
  /* --------------------------------------------
  * Here's how you would do cross-component hovers
  */
  const dispatch = createEventDispatcher();
  function handleMousemove(d) {
    return function handleMousemoveFn(e) {
      raise(this); // Layer Cake's built-in raise function (adapted from d3-selection) ensures the hovered-over SVG element is always on top of its siblings. 
      // When the element gets raised, it flashes 0,0 for a second so skip that
      if (e.layerX !== 0 && e.layerY !== 0) {
        dispatch('mousemove', { e, props: d });
      }
    }
  }

</script>


<!-- <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
  <defs>
    <marker id="arrowhead" markerWidth={triangleHeight} markerHeight={triangleBase} stroke="currentColor"
      refX="0" refY={triangleBase/2} orient="auto" markerUnits="userSpaceOnUse">
      <polygon points="0 0, {triangleHeight} {triangleBase/2}, 0 {triangleBase}"></polygon>
    </marker>
  </defs>
</svg> -->


<g class="arrow-chart">
{#each $data as group, n}
  {#if n === 0}
  <defs>
    {#each group.values as d, i}
      <!-- Gets *added* at the end of the line -->
      <!-- 0.5 to offset it very slightly, get rid of thin white line rendered between line and marker -->
      <marker 
        id="arrowhead-{i}" 
        markerWidth={triangleHeight} 
        markerHeight={triangleBase} 
        fill={$custom.zLookup[$x(d)] ? $custom.zLookup[$x(d)]['stroke'] : stroke} 
        refX="0.5" 
        refY={triangleBase/2} 
        orient="auto" 
        markerUnits="userSpaceOnUse">
        <polygon points="0 0, {triangleHeight} {triangleBase/2}, 0 {triangleBase}"/>
      </marker>
      <!-- Half a rounded square (also *added* before the start of the line) -->
      <!-- With this marker, the aspect ratio is 1:2 (where 2 is the arrowWidth/ markerHeight) -->
      <marker id="round-{i}" viewBox="0 0 2 2" refX="1" refY="1" markerWidth="1" orient="auto">
        <rect 
          width="2" 
          height="2" 
          rx="1" 
          ry="1"
          fill={$custom.zLookup[$x(d)] ? $custom.zLookup[$x(d)]['stroke'] : stroke} 
        />
      </marker> 
      <!-- With this marker, the aspect ratio is 1:4 (where 4 is the arrowWidth/ markerHeight) -->
      <!-- <marker id="round-{i}" viewBox="0 0 0.5 2" refX="0.5" refY="1" markerWidth="0.5" markerHeight="1" orient="auto" >
        <rect 
          width="1" 
          height="2" 
          rx="1" 
          ry="0.5"
          fill={$custom.zLookup[$x(d)] ? $custom.zLookup[$x(d)]['stroke'] : stroke} 
        />
      </marker>  -->
    {/each}
  </defs>
  {/if}
  <g class="arrow-group" id={group.key} style="transform: translate({$zScale(group.key)}px, 0)">
  {#each group.values as d, i}
    <line 
      class="arrow"
      data-id={i}
      data-value={$r(d)}
      data-change={$r(d) - $y(d)}
      data-length={lengthInPixels(d)}
      x1={$xGet(d)} 
      y1={y1Get(d)}  
      x2={$xGet(d)} 
      y2={y2Get(d)} 
      stroke={$custom.zLookup[$x(d)]
        ? $custom.zLookup[$x(d)]['stroke'] : stroke} 
      stroke-width={arrowWidth} 
      marker-end="url(#arrowhead-{i})" 
      marker-start={(lengthInPixels(d) >= (triangleHeight + arrowWidth*rectRatio)) ? `url(#round-${i})` : ""}
    />

    <rect 
      class="arrow-hover-support {$x(d)}"
      x={$xGet(d) - triangleBase/2} 
      y={Math.min($yScale($y(d)), $yScale($r(d)))}  
      height={Math.abs($yScale($y(d)) - $yScale($r(d)))}
      width={triangleBase}
      fill={$custom.zLookup[$x(d)]
        ? $custom.zLookup[$x(d)]['stroke'] : stroke} 
      opacity={0}
      on:dblclick|stopPropagation={e => window.open(`#/${ $x(d)=='cleaner' ? 'decarbon' : $x(d) }`, "_self")} 
      on:mouseover={(e) => dispatch('mousemove', { e, props: d })}
      on:focus={(e) => dispatch('mousemove', { e, props: d })}
      on:mousemove={handleMousemove(d)}
      on:mouseout={(e) => dispatch('mouseout', { e, props: d })}
      on:blur={(e) => dispatch('mouseout', { e, props: d })}
    />
  {/each}
  </g>
{/each}
</g>


<style>
  .arrow-hover-support {
    cursor: pointer;
  }</style>