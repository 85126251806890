<script>
  import { getContext } from 'svelte';

  const {
    data,
    xGet,
    yGet,
    zGet,
    xScale,
    yScale,
    xRange,
    yRange,
    xDomain,
    yDomain,
    custom
  } = getContext('LayerCake');

  export let stroke = '#ab00d6';
  export let strokeWidth = 2;

  $: path = (values) => {
    return (
      'M' +
      values
        .map((d) => {
          return $xGet(d) + ',' + $yGet(d);
        })
        .join('L')
    );
  };
</script>

<g class="line-group">
  {#each $data as group}
    <path class="path-line {$custom.zLookup[group.key] ? $custom.zLookup[group.key]['class'] : ""}" 
      d={path(group.values)} 
      stroke={$custom.zLookup[group.key]
        ? $custom.zLookup[group.key]['stroke']
        : $zGet(group)['stroke']
        ? $zGet(group)['stroke']
        : stroke} 
      stroke-width={$custom.zLookup[group.key]
        ? $custom.zLookup[group.key]['strokeWidth']
        : $zGet(group)['strokeWidth']
        ? $zGet(group)['strokeWidth']
        : strokeWidth} 
      opacity={$custom.zLookup[group.key]
        ? $custom.zLookup[group.key]['opacity']
        : 1
      }
    />
  {/each}
</g>

<style>
  .path-line {
    fill: none;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .path-line.dashed{
    stroke-dasharray: 10,10;
  }

  .path-line.dashed.ERP_legislated{
    stroke-dasharray: var(--dash_legislated);
  }

  .path-line.dashed.ERP_developing {
    stroke-dasharray: var(--dash_developing);
  }

  .path-line.dashed.ERP_announced_more {
    stroke-dasharray: var(--dash_announced_more);
  }

  .path-line.dashed.ERP_announced_less {
    stroke-dasharray: var(--dash_announced_less);
  }</style>
