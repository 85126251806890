<script>
  import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  } from "@rgossiaux/svelte-headlessui";

  import Icon from "./assets/Icon.svelte";

  export let options = [
    { id: 1, name: "Option 1", unavailable: false },
    { id: 2, name: "Option 2", unavailable: false },
    { id: 3, name: "Option 3", unavailable: false }
  ];

  export let selectedOption = options[0];

  export let titleSize = false;

  // function truncate(text, totalChars, endChars = 0) {
  //   //https://svelte.dev/repl/28f1898c9aab465ba9c9a897bc41285d?version=3.48.0
	// 	endChars = Math.min(endChars, totalChars);
	// 	const start = text.slice(0, totalChars - endChars);
	//   const end = endChars > 0 ? text.slice(-endChars) : '';
		
	// 	if (start.length + end.length < text.length) {
	// 		return start + '…' + end;	
	// 	} else {
	// 		return text;
	// 	}
	// }

</script>

<div class="dropdown" class:title={titleSize}>
  <Listbox value={selectedOption} on:change={(e) => (selectedOption = e.detail)} let:open>
    <ListboxButton>
      <div class="button-text" class:title={titleSize}>{selectedOption.name}</div>
      <div class="arrow"><Icon name="chevron-down" direction={open ? "s" : "n"}/></div>
    </ListboxButton>
    <ListboxOptions>
      {#each options as opt (opt.id)}
        <span class="option-wrapper">
        <ListboxOption 
          value={opt} disabled={opt.unavailable}
          class={({ active, selected }) => (active || selected ? "active" : "")}
          let:selected>
          {#if selected}
            <Icon name="check" strokeWidth="3"/>
          {/if}
          {opt.label}
        </ListboxOption>
      </span>
      {/each}
    </ListboxOptions>
  </Listbox>
</div>

<style>
  .dropdown {
    max-width: 100%;
    --padding: 8px;
    --curve: var(--border-curve);
    position: relative;
  }

  .button-text {
    margin: 0;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    font-family: var(--body-font);
  }

  .title {
    font-size: 1.17rem;
  }
  
  /* :global by itself is truly global and the styles are applied wherever 
  there is a match.  But if prefixed with an element/class selector in the current 
  component it applies the styles to all child elements with a matching selector. 	*/
  * :global(ul) {
    list-style-type: none;
    cursor: pointer;
    background-color: var(--neutral-faded);
    border-radius: var(--curve);
    position: absolute;
    z-index: 1;
    width: 100%;
    font-size: 0.95rem;
  }

  /* .title :global(ul){
    font-size: 1rem;
  } */

  * :global(ul li) {
    padding: var(--padding);
    border-radius: var(--curve);
  }

  * :global(ul li.active) {
    /* background-color: #999; */
    background-color: var(--neutral-accent);
    font-weight: 500;
  }

  * :global(button) {
    background-color: #FFF;
    padding: var(--padding);
    cursor: pointer;
    color: var(--text-main);
    font-weight: 500;
    border: solid 0.25px hsla(177.5, 100%, 13.9%, 0.1);
    border-radius: var(--curve);
    padding-right: 25px;
    position: relative;
    max-width: 100%;
    width: 100%;
  }

  .arrow { 
    position: absolute;
    top: 50%;
    right: 5px;
    display:inline-block;
    transform: translateY(-50%);
  }</style>