<script>
  import { getContext } from 'svelte';
  import { format } from 'd3-format';

  import QuadTree from './QuadTree.svelte';

  const { data, width, yScale, config, custom } = getContext('LayerCake');

  const commas = format(',');
  const titleCase = (d) => d.replace(/^\w/, (w) => w.toUpperCase());

  export let tooltipOffset = 20;
  export let dataset = undefined;
  export let formatTitle = (d) => d;
  export let formatKey = (d) => titleCase(d);
  export let formatValue = (d) => (isNaN(+d) ? d : commas(d));
  export let displayKey = (d) => true;

  // Let there be an optional prop where you can pass in an x and y different from the context. 
  // Comes in handy when the dataset for the tooltip (not stacked) is different from what is plotted (stacked)
  export let tooltipX = undefined;
  export let tooltipY = undefined;

  const w = 175;
  const w2 = w / 2;
  let top = 0;

  const xKey = typeof ($config.x) === 'string' ? $config.x : $custom.xKey


  function getResults(result) {
    if (Object.keys(result).length === 0) return [];
    const rows = Object.keys(result)
      .filter((d) => d !== xKey)
      .map((key) => {
        return {
          key,
          value: result[key],
        };
      })
    return rows.sort((a,b) => a.key.includes("NZP") || a.key == "share_nonemitting" ? 1 : -1); // always put NZP last and non-emitting on the bottom
  }

  function sortRows(rows){ // Sort keys by the loweset value
    return rows.sort((a, b) => a.value - b.value);
  }
</script>

<QuadTree
  dataset={dataset || $data}
  y="x"
  customX={tooltipX}
  customY={tooltipY}
  let:x
  let:y
  let:visible
  let:found
  let:e>
  {#if visible === true}
    <div style="left:{x}px;" class="line" />
    <div
      class="tooltip custom"
      style="
        width:{w}px;
        display: {visible ? 'block' : 'none'};
        top:{$yScale(sortRows(getResults(found).filter(d => displayKey(d.key), found[xKey]))[0].value) - tooltipOffset}px;
        left:{Math.min(Math.max(w2, x), $width - w2)}px;">
      <div class="title {xKey}">{formatTitle(found[xKey])}</div>
        <div class="data-rows">
        {#each getResults(found) as row}
          {#if displayKey(row.key, found[xKey])}
            <div class="row {row.key}" class:NZP={row.key.includes("NZP")}>
              <div class="key">{formatKey(row.key)}:</div>
              <div class="value">{formatValue(row.value)}</div>
            </div>
          {/if}
        {/each}
      </div>
    </div>
  {/if}
</QuadTree>

<style>
  .tooltip {
    position: absolute;
    font-size: 0.7rem;
    pointer-events: none;
    /* border: 1px solid #ccc; */
    background: rgba(255, 255, 255, 0.85);
    transform: translate(-50%, -100%);
    padding: 5px;
    z-index: 15;
    pointer-events: none;
  }

  .tooltip.custom {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-curve);
    background: rgba(255, 255, 255, 0.95);
  }
  .line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    border-left: 1px dotted var(--default);
    pointer-events: none;
    opacity: 0.5;
  }
  /* .tooltip,
  .line {
    transition: left 250ms ease-out, top 250ms ease-out;
  } */
  .title {
    /* font-weight: bold; */
    font-size: 0.7rem;
  }

  .row:not(:last-child) {
    margin-bottom: 8px;
  }

  .row {
    line-height: 1.2;
  }

  .value {
    font-weight: 600;
    font-size: 0.75rem;
  }

  .row.NZP {
    font-style: italic;
  }

  .row:not(:first-of-type) {
    font-size: 0.7rem;
  }

  .row.NZP:not(:first-of-type) .value {
    font-weight: inherit;
    font-size: inherit;
  }</style>
