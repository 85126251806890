
<script>
  import mq from "@/stores/mq.js";
  import { getContext } from 'svelte';
  import { formatDefaultVal } from "@/utils/dataHelpers.js"

  import Bracket from './Bracket.svelte'

  const { data, xGet, yGet, x, y, xScale, yScale, zScale, custom, xRange, height, padding } = getContext('LayerCake');

  export let radius = 3;
  export let triangleBase = 8;
  export let triangleHeight = 6;

  export let targetValue;
  export let actualValue;

  $: xPos = $xRange[1] + $custom.bgPad + 8;

  $: getY1 = g => $yScale($custom.getStartValue(g.values))
  $: getY2 = g => $yScale($custom.getEndValue(g.values))

  $: arrowUp = g => $custom.getEndValue(g.values) > $custom.getStartValue(g.values)
  $: y2Adjust = g => triangleHeight * (arrowUp(g) ? 1 : -1) // triangle

  $: getAdjustedLabelY1 = g => getAdjustedLabelPos(getY1(g), getY2(g), $yScale(targetValue))[0]
  $: getAdjustedLabelY2 = g => getAdjustedLabelPos(getY1(g), getY2(g), $yScale(targetValue))[1]

  const getAdjustedLabelPos = (y1, y2, target) => {
    const getPosAdjust = (diff, threshold=16, shift=10) => {
      return Math.abs(diff) > threshold ? 0 : (diff > 0 ? 1 : -1) * shift + ((diff > 0 ? 0 : -6)) // if shifting up, make additional room
    }
    let y1_adjustment = getPosAdjust(y1 - target)
    let y2_adjustment = getPosAdjust(y2 - target)
    const finalTweak = getPosAdjust((y2 + y2_adjustment) - (y1 + y1_adjustment))
    
    return [y1 + y1_adjustment - finalTweak/2,y2 + y2_adjustment + finalTweak/2]
  }

  const formatLabel = formatDefaultVal;

</script>


<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
  <defs>
    <marker id="arrowhead-overall" markerWidth={triangleHeight} markerHeight={triangleBase} 
      refX="0" refY={triangleBase/2} orient="auto" markerUnits="userSpaceOnUse">
      <polygon fill="currentColor" points="0 0, {triangleHeight} {triangleBase/2}, 0 {triangleBase}"></polygon>
    </marker>
    <!-- https://stackoverflow.com/questions/13069446/simple-fill-pattern-in-svg-diagonal-hatching -->
    <pattern id="diagonal-hatch-miss" width="10" height="10" patternTransform="rotate(-45 0 0)" patternUnits="userSpaceOnUse">
      <rect fill="currentColor" x="0" y="0" width="10" height="1"/>
    </pattern>    
    <pattern id="diagonal-hatch-meet" width="10" height="10" patternTransform="rotate(-45 0 0)" patternUnits="userSpaceOnUse">
      <rect fill="currentColor" x="0" y="0" width="10" height="1"/>
    </pattern>  
  </defs>
</svg>


<line class="guide-lines faint" x1={$zScale($data[0].key) + $xRange[1]+ ($custom.arrowWidth/2 + $custom.bgPad)} x2={$zScale($data[1].key)-$custom.bgPad} y1={getY2($data[0])} y2={getY2($data[0])}/>
{#each $data as group, n}
  <rect class="arrow-background" x={-$custom.bgPad} y={-$padding.top} style="transform: translate({$zScale(group.key)}px, 0)" width={$xRange[1]+ ($custom.arrowWidth/2 + 2*$custom.bgPad)} height={$height+$padding.top+$padding.bottom}
  fill={$custom.getBackgroundFills(group.key)}/>
  <g class="arrow-annotations" style="transform: translate({$zScale(group.key)}px, 0)">
    <line class="overall-change" y1={getY1(group)} y2={getY2(group) + y2Adjust(group)} x1={xPos} x2={xPos} 
      marker-end="url(#arrowhead-overall)"/>
    <g class="start label {n == 0 ? 'initial' : 'default'}">
      <line class="guide-lines" x1={-$custom.bgPad} x2={$xRange[1]+ ($custom.arrowWidth/2 + $custom.bgPad)} y1={getY1(group)} y2={getY1(group)}/>

      <circle class="anchor" cx={xPos} cy={getY1(group)} r={radius}/>
      <text class="label-text" class:smaller={$mq.mobile} 
        class:hidden={n == $data.length -1 && Math.abs(getY1(group) - $yScale(targetValue)) < 2}
        x={xPos + 6} 
        y={n !== $data.length -1 ? getY1(group) : getAdjustedLabelY1(group)} 
        dy=".35em" dx=".3em">
        {formatLabel($custom.getStartValue(group.values))} <tspan class="units" dx=".1em">Mt</tspan>
      </text>
    </g>
    <g class="end label {n == $data.length - 1 ? 'final': 'default'}">
      <line class="guide-lines {group.key}" x1={-$custom.bgPad} x2={$xRange[1]+ ($custom.arrowWidth/2 + $custom.bgPad)} y1={getY2(group)} y2={getY2(group)}/>
      <text class="label-text" class:smaller={$mq.mobile} 
        class:hidden={n == $data.length -1 && Math.abs(getY2(group) - $yScale(targetValue)) < 2}
        x={xPos + 6} 
        y={n !== $data.length -1 ? getY2(group) : getAdjustedLabelY2(group)} 
        dy=".35em" dx=".3em">
        {formatLabel($custom.getEndValue(group.values))} <tspan class="units" dx=".1em">Mt</tspan>
      </text>
    </g>

  <!-- For the last group (projected)-->
    {#if n == $data.length -1}
      <!-- Shaded rectangle -->
      <rect class="target-background"
        fill="{actualValue > targetValue ? 'url(#diagonal-hatch-miss)' : 'url(#diagonal-hatch-meet)'}"
        x={-$custom.bgPad} y={$yScale(Math.max(actualValue, targetValue))} width={$xRange[1]+ ($custom.arrowWidth/2 + 2*$custom.bgPad)} height={Math.abs($yScale(targetValue) - $yScale(actualValue))}/>
      {#if actualValue > targetValue}
      <!-- Target bracket -->
        <Bracket orientation={"left"} xPx={-$custom.bgPad - 15} y1={targetValue} y2={actualValue} stroke={$custom.getTargetFill(actualValue)}/>
      {/if}
      <!-- Horizontal line for target -->
      <line class="arrow-target" x1={-$custom.bgPad} x2={$xRange[1]+ ($custom.arrowWidth/2 + $custom.bgPad)} y1={$yScale(targetValue)} y2={$yScale(targetValue)}/>
    {/if}
  </g>
{/each}

<style>
  .label .label-text {
    font-size: 0.75rem;
    fill: var(--default);
  }

  .label .label-text.smaller {
    font-size: 0.625rem;
  }

  .label .label-text .units {
    font-size: 0.8em;
  }

  .hidden {
    opacity: 0;
  }

  .overall-change {
    /* stroke: var(--grey); */
    stroke: var(--default);
    stroke-width: 1;
  }

  #diagonal-hatch-miss {
    color: var(--red);
    fill-opacity: 0.3;
  }
  #diagonal-hatch-meet {
    color: var(--neutral);
    fill-opacity: 0.3;
  }

  .label.default .anchor{
    fill: var(--default);
  }

  .label.initial .anchor{
    /* fill: var(--grey); */
    fill: var(--default);
  }
  
  .label.final .anchor{
    stroke: var(--default);
    fill-opacity: 0.8;
    stroke-width: 1;
  }

  .arrow-target {
    stroke: var(--default);
    stroke-width: 2;
  }

  .guide-lines {
    stroke: var(--grey);
    stroke-width: 0.5;
    opacity: 0.5;
    stroke-linecap: round;
  }

  .guide-lines.faint {
    opacity: 0.5;
    stroke-width: 0.5;
    stroke-dasharray: 0.5,4;
  }</style>
