<script>
  import * as langHandler from '@/utils/language-handler.js';
  import { onMount } from "svelte";

  const domainURL = "https://440megatonnes.ca" + (langHandler.locale=='fr' ? '/fr' : '')

  let navDropOpen = false;
  let searchDrawerOpen = false;
  let siteSearchInput;
  let siteSearchToggle;

  const handleToggleClick = (e) => {
    navDropOpen = !navDropOpen;
    const expanded = e.currentTarget.getAttribute('aria-expanded') === 'true' || false
    e.target.setAttribute('aria-expanded', !expanded)
  }

  /**
   * Open the search drawer
   */
  const openSearch = () => {
    searchDrawerOpen = true;
    // console.log('open search')
    // wish we didn't need to use a setTimeout, but I've run out of time/patience;
    setTimeout(() => {siteSearchInput.focus()},10);
  }

  /**
   * Close the search drawer
   */
  const closeSearch = () => {
    searchDrawerOpen = false;
    siteSearchToggle.focus();
  }

  /**
   * Handle a click event that should toggle the search drawer.
   */
    const handleSearchToggle = (e) => {
    if (searchDrawerOpen) {
      closeSearch()
    } else {
      openSearch()
    }
  }

  /**
   * Ensures the focus is returned to the search toggle if shift+tabbing
   * out of the search drawer.
   */
    const handleExitTop = (e) => {
    var key = e.which || e.key

    if (key === 9 && e.shiftKey) {
      e.stopPropagation()
      e.preventDefault()
      closeSearch()
    }
  }

  /**
   * Ensures focus is returned to the search toggle if tabbing out of the
   * search drawer.
   */
  const handleExitBottom = (e) => {
    var key = e.which || e.key

    if (key === 9) {
      e.stopPropagation()
      e.preventDefault()
      closeSearch()
    }
  }

  /**
   * Returns the focus to the search toggle if the escape button is clicked
   * while focus is in the search drawer.
   */
  const handleEscape = (e) => {
    var key = e.which || e.keyCode

    // 27 is ESC key
    if (key === 27 && closeSearch()) {
      e.stopPropagation()
      e.preventDefault()
    }
  }





  onMount(async () => {

    document.addEventListener('keyup', handleEscape)

  });


</script>

<header class="site-header" class:mod-menu-open={navDropOpen} class:mod-search-open={searchDrawerOpen} role="banner">
  <div class="site-header-inner layout-wrapper">
    <div class="site-header-column mod-left">
      <h1 class="site-header-branding">
        <a class="site-header-branding-link" href="{domainURL}/" rel="home">
          <span class="u-sr-only">440 Megatonnes: Tracking Canada’s path to net zero</span>
          
          <svg class="site-logo" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="52.5" cy="52.5" r="52.5" fill="#3B6A73"></circle>
            <g clip-path="url(#clip0_732_3492)">
              <path d="M87.2424 30.6636C85.2043 28.7681 82.5309 27.8247 79.2046 27.8247C75.8784 27.8247 73.1962 28.7681 71.1669 30.6636C68.8553 32.8765 67.6995 35.9094 67.6995 39.7357V61.768H61.4616V28.3801H53.9091L38.513 61.768H34.975V28.3801H27.4225L10.5 65.0918V69.3853H27.3431V76.8705H34.9662V69.3853H53.8209V76.8705H61.444V69.3853H68.1494C68.6876 71.4396 69.6846 73.1764 71.1492 74.5782C73.1785 76.4738 75.8607 77.4171 79.187 77.4171C82.5133 77.4171 85.1954 76.4738 87.2247 74.5782C89.5364 72.3653 90.6922 69.3413 90.6922 65.5061V39.7357C90.6922 35.9006 89.5364 32.8765 87.2247 30.6636H87.2424ZM27.3431 61.768H20.4082L27.3431 44.382V61.768ZM53.8209 61.768H46.886L53.8209 44.382V61.768ZM79.1958 71.3426L71.7227 61.9795H76.0813V39.7357C76.0813 38.0694 76.3636 36.9233 76.9195 36.2708C77.4047 35.7154 78.1635 35.4421 79.2046 35.4421C80.2458 35.4421 81.0398 35.7154 81.4898 36.2708C82.0456 36.8704 82.328 38.0253 82.328 39.7357V61.9795H86.6777L79.2046 71.3426H79.1958Z" fill="white"></path>
              <path d="M79.1957 71.3429L71.7227 61.9798H76.0812V39.736C76.0812 38.0697 76.3635 36.9235 76.9194 36.2711C77.4047 35.7157 78.1634 35.4424 79.2046 35.4424C80.2457 35.4424 81.0397 35.7157 81.4897 36.2711C82.0456 36.8706 82.3279 38.0256 82.3279 39.736V61.9798H86.6776L79.2046 71.3429H79.1957Z" fill="#F94144"></path>
            </g>
            <defs>
              <clipPath id="clip0_732_3492">
                <rect width="80.201" height="49.6012" fill="white" transform="translate(10.5 27.8247)"></rect>
              </clipPath>
            </defs>
          </svg>
        </a>
      </h1>
      <div class="voila-dashboard-title">
        <a style="text-decoration:inherit;" href="#/">{langHandler.getTranslation('pathways_tracker')}</a>
      </div>
      <p class="site-header-branding-text">
        {#if langHandler.locale == "fr"}
          Des analyses basées sur des données de <a class="site-header-cci-link" href="https://institutclimatique.ca/">l’Institut climatique du Canada.</a>
        {:else}
          Data-driven insights from the <a class="site-header-cci-link" href="https://climateinstitute.ca/">Canadian Climate Institute.</a>
        {/if}
      </p>
    </div>

    <div class="site-header-column mod-center">
      
<nav class="site-nav">
	<button class="site-nav-toggle" on:click={handleToggleClick}>
		<span class="u-sr-only">Menu</span>
		<svg class="site-nav-toggle-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path class="site-nav-toggle-icon-path mod-open" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
			<path class="site-nav-toggle-icon-path mod-close"  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
		</svg>
	</button>
	<div class="site-nav-dropdown" class:is-open={navDropOpen}>
		
    <div class="site-search" class:is-showing={searchDrawerOpen}>
      <div class="site-search-layout layout-wrapper">
        <form role="search" method="get" id="search-form" class="site-search-form" action="{domainURL}/">
          <label class="site-search-label u-sr-only" for="s">Search for:</label>
          <div class="site-search-bar">
            <input class="site-search-input" bind:this={siteSearchInput} on:keydown={handleExitTop} type="search" placeholder="Search…" name="s" id="search-input" value="">
            <button class="site-search-submit" type="submit">
              <span class="u-sr-only">Search</span>
              <svg class="site-search-submit-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
          </div>
          <button class="site-search-close" type="button" on:click={closeSearch} on:keydown={handleExitBottom}>
            <span class="u-sr-only">Close search</span>
            <svg class="site-search-close-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          </button>
        </form>
      </div>
    </div>
		<ul id="menu-primary-menu" class="site-nav-menu">
      <li id="menu-item-21" class="scheme-blue menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-21"><a href="{domainURL}/tracking-440/">{langHandler.getTranslation('nav__tracking_progress')}</a>
        <ul class="sub-menu">
          <li id="menu-item-38" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-38"><a href="{domainURL}/pathways/">{langHandler.getTranslation('nav__pathways')}</a></li>
          <li id="menu-item-213" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-213"><a href="{domainURL}/policies/">{langHandler.getTranslation('nav__policies')}</a></li>
          <li id="menu-item-98" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-98"><a href="{domainURL}/corporate/">{langHandler.getTranslation('nav__corporate')}</a></li>
          <li id="menu-item-97" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-97"><a href="{domainURL}/beyond-2030/">{langHandler.getTranslation('nav__beyond_2030')}</a></li>
        </ul>
      </li>
      <li id="menu-item-17" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-17"><a href="{domainURL}/insights/">{langHandler.getTranslation('nav__insights')}</a></li>
      <li id="menu-item-320" class="scheme-orange menu-item menu-item-type-post_type menu-item-object-page menu-item-320"><a href="{domainURL}/data/">{langHandler.getTranslation('nav__data')}</a></li>
      <li id="menu-item-20" class="scheme-gold menu-item menu-item-type-post_type menu-item-object-page menu-item-20"><a href="{domainURL}/about/">{langHandler.getTranslation('nav__about')}</a></li>
    </ul>
		<a class="site-nav-cci-link u-small" href={langHandler.locale == 'fr' ? "https://institutclimatique.ca/" : "https://climateinstitute.ca"}>{langHandler.locale == 'fr' ? "l'Institut climatique" : "Climate Institute"}</a>
    <a class="site-header-subscribe" href="{domainURL}/newsletter-signup">{langHandler.getTranslation('nav__subscribe')}</a>
    {#if langHandler.locale=="fr"}
      <a href="https://dashboard.440megatonnes.ca/" class="language-toggle">English</a>
    {:else}
      <a href="https://dashboard.440megatonnes.ca/fr/" class="language-toggle">Français</a>
    {/if}
	</div>
</nav>    
</div>

    <div class="site-header-column mod-right">
      <button class="site-search-toggle" bind:this={siteSearchToggle} on:click={handleSearchToggle}>
        <span class="u-sr-only">Toggle search</span>
        <svg class="site-search-toggle-icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
      </button>
      <a class="site-header-subscribe" href="{domainURL}/newsletter-signup">{langHandler.getTranslation('nav__subscribe')}</a>
      <div class="site-header-language-toggle">
        {#if langHandler.locale=="fr"}
          <a href="https://dashboard.440megatonnes.ca/" class="language-toggle">English</a>
        {:else}
          <a href="https://dashboard.440megatonnes.ca/fr/" class="language-toggle">Français</a>
        {/if}
      </div>
    </div>
  </div>
</header>

<style>
  /*!*************************************************************************************************************************************************************************************************************!*\
  !*** css ../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../node_modules/sass-loader/dist/cjs.js!./global/styles/base.sass ***!
    \*************************************************************************************************************************************************************************************************************/
  header {
    --font-sans-serif: "Montserrat", sans-serif;
    --font-condensed: "Oswald", sans-serif;
    --font-serif: "PT Serif", serif;
    --color-txt: #4a4a4a;
    --color-txt-inv: #ffffff;
    --color-primary: #43aa8b;
    --color-primary-muted: #86aca6;
    --color-primary-light: #deede8;
    --color-primary-dark: #004845;
    --color-footer: #627e83;
    --header-height: 4.375rem;
    --header-height-mobile: 3.125rem;
    --layout-width: 76.25rem;
    
    --color-heading: var(--color-primary-dark);
    --color-accent: #f94144;
  }

  header {
    /* font-size: 10px; */
    color: var(--color-txt);
    font-family: var(--font-sans-serif);
    line-height: 1.3;
    font-size: 62.5%;
  }


  /* From staging site */
  .u-sr-only:not(:focus):not(:active) {
  clip:rect(0 0 0 0);
  -webkit-clip-path:inset(50%);
          clip-path:inset(50%);
  height:1px;
  overflow:hidden;
  position:absolute;
  white-space:nowrap;
  width:1px
  }

/*! CSS Used from: https://440-megatonnes.flywheelsites.com/wp-includes/css/dist/block-library/style.min.css?ver=6.0.3 ; media=all */
@media all{
ul{box-sizing:border-box;}
}
/*! CSS Used from: https://440-megatonnes.flywheelsites.com/wp-content/themes/440-megatonnes/public/main.css?ver=1.5.1 ; media=screen */

h1{font-size:1.5rem;margin:.41875rem 0;}
a{background-color:transparent;}
button,input{font-family:inherit;font-size:100%;line-height:1.15;margin:0;}
button,input{overflow:visible;}
button{text-transform:none;}
[type=button],[type=submit],button{-webkit-appearance:button;}
[type=button]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0;}
[type=button]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText;}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px;}
*,:after,:before{box-sizing:inherit;}
h1{color:var(--color-heading);font-family:var(--font-condensed);line-height:1;text-transform:uppercase;}

.scheme-orange{--color-primary:#ff6e1c;--color-primary-muted:#dfb59f;--color-primary-light:#fee8dc;}
.scheme-blue{--color-primary:#3f6a91;--color-primary-muted:#688197;--color-primary-light:#dbe6f0;}
.scheme-gold{--color-primary:#ffc12d;--color-primary-muted:#e6d7b2;--color-primary-light:#fbf6e9;}
.layout-wrapper{margin:0 auto;max-width:var(--layout-width);width:calc(100% - 2.5rem);}
.site-header{
  background-color:var(--color-primary-light);
  color:var(--color-txt);
  display:flex;
  height:var(--header-height-mobile);
  justify-content:space-between;
  top:0;
  width:100%;
  position: relative;
  z-index:102;
}

.site-header-inner{display:flex;position:relative;}
.site-header-column{align-items:center;display:flex;}
.site-header-column.mod-left,.site-header-column.mod-right{flex-grow:1;width:6.25rem;}
.site-header-column.mod-right{display:none;justify-content:flex-end;}

.site-header-branding{
  position: fixed;
  top: -0.41875rem;
  z-index: 102;
}
.voila-dashboard-title {
  /* mobile version */
  font-size: 0.75rem;
  font-weight: 500;
  margin: auto;
  color: var(--default);
  position: absolute;
  top: calc(3.75rem /2);
  left: calc(3.75rem + 0.5rem);
}


.site-header-branding-link{--_logo-size:3.75rem;color:inherit;display:block;height:1.75rem;left:0;position:absolute;text-decoration:none;top:0;transform:translateY(50%);width:var(--_logo-size);z-index:101;}

.site-nav{font-size:0.75rem;font-weight:500;}
.site-nav{text-transform:uppercase;z-index:100;}
.site-nav-toggle{background-color:transparent;border:none;color:var(--color-primary-dark);cursor:pointer;}

.site-nav-toggle-icon{--_icon-size:1.5rem;display:block;height:var(--_icon-size);width:var(--_icon-size);}
.site-nav-toggle-icon-path.mod-close{display:none;}
.site-nav-dropdown{background-color:var(--color-primary-light);bottom:0;display:none;left:0;position:fixed;right:0;top:var(--header-height-mobile);}

.site-nav-menu,.site-nav-menu .sub-menu{list-style:none;margin:0;padding:0;}

.site-nav-menu a{color:inherit;display:block;text-decoration:none;}
.site-nav-menu .sub-menu{background-color:var(--color-primary-light);color:var(--color-txt);}


.site-nav-menu .menu-item a{padding:0.625rem 1.25rem;}
.site-nav-menu>.menu-item{background-color:var(--color-primary);color:var(--color-txt-inv);position:relative;}

.site-nav-menu>.menu-item>a{align-items:center;display:flex;height:100%;}
.site-nav-menu .sub-menu{padding:0 1.25rem;}

.site-nav-menu .sub-menu .menu-item{border-top:1px solid var(--color-txt-inv);}
.site-nav-menu .sub-menu .menu-item:first-of-type{border-top:none;}
.site-nav-cci-link{background-color:var(--color-primary-dark);color:var(--color-txt-inv);display:block;padding:0.625rem 1.25rem;text-decoration:none;}
.site-search{--_padding-block:1.25rem;background-color:var(--color-primary-light);-webkit-padding-after:calc(var(--_padding-block)*2);padding-block-end:calc(var(--_padding-block)*2);-webkit-padding-before:calc(var(--_padding-block) + 0.875rem);padding-block-start:calc(var(--_padding-block) + 0.875rem);width:100%;}
.site-search-form{display:flex;position:relative;}
.site-search-bar{background-color:var(--color-txt-inv);border-radius:.35rem;display:flex;flex-grow:1;position:relative;}
.site-search-input{border:none;border-radius:.35rem;flex:1;font-size:0.75rem;line-height:1.25;padding-block:.4375rem;padding-inline:0.875rem;width:100%;}
.site-search-submit{align-items:center;display:flex;height:1.8125rem;justify-content:center;padding:0;padding-inline:.5rem;width:auto;}
.site-search-close,.site-search-submit,.site-search-toggle{background-color:transparent;border:none;color:var(--color-txt);}
.site-search-close,.site-search-toggle{cursor:pointer;display:none;margin-left:0.75rem;}
.site-search-close-icon,.site-search-submit-icon,.site-search-toggle-icon{--_icon-size:1.5rem;display:block;height:var(--_icon-size);width:var(--_icon-size);}
.site-search-close{margin-left:0.75rem;}
.site-logo{position:fixed;height:var(--_logo-size),6.5625rem;width:var(--_logo-size),6.5625rem;}



.site-header.mod-menu-open .site-nav .site-nav-toggle-icon-path.mod-open{display:none;}
.site-header.mod-menu-open .site-nav .site-nav-toggle-icon-path.mod-close{display:block;}
.site-header.mod-menu-open {position: fixed;}
.site-header.mod-search-open {position: fixed;}
.site-nav-dropdown.is-open{display:block;}
.site-search.is-showing {display: block;}

/* Newly added styles from Helios Design Labs */
.site-header-branding-text{color:var(--color-heading);display:none;font-size:0.75rem;font-weight:500;letter-spacing:-1.5%;line-height:1.25;margin-left:calc(2.125rem + 6.5625rem);max-width:23ch;}
.site-header-cci-link{color:inherit;text-decoration:none;}
.site-header-cci-link:hover{text-decoration:underline;}
.site-header-subscribe{background-color:var(--color-accent);color:var(--color-txt-inv);display:block;font-family:var(--font-sans-serif);font-size:0.75rem;font-weight:800;letter-spacing:.01em;line-height: 1;margin-inline: 0;padding:0.5625rem;-webkit-text-decoration-color:transparent;text-decoration-color:transparent;text-transform:uppercase;transition:-webkit-text-decoration-color .3s;transition:text-decoration-color .3s;transition:text-decoration-color .3s, -webkit-text-decoration-color .3s;}
.language-toggle{color: inherit;font-size:0.75rem;font-weight:500;text-decoration:none;text-transform:uppercase;}
.language-toggle:hover{text-decoration:underline;}
.site-nav .site-header-subscribe{font-weight:500;line-height:1.4;margin-left:0;padding-block:0.625rem;padding-inline:1.25rem;}
.site-nav .language-toggle {background-color:var(--color-primary-muted);color:var(--color-txt-inv);display:block;padding:0.625rem 1.25rem;text-decoration:none;}

/* Remove this when the language toggle is deployed... */
/* .language-toggle,.site-nav .language-toggle {display: none;} */

@media (min-width:900px){
  .site-nav-menu>.menu-item{background-color:transparent;color:var(--color-txt);padding:0;}
  .site-nav-menu>.menu-item:hover>.sub-menu{display:block;}
  .site-header{height:var(--header-height);}
  .site-header-column.mod-right{display:flex;}
  .site-header-branding-link{--_logo-size:6.5625rem;}
  .site-nav-toggle{display:none;}
  .site-nav-dropdown{bottom:auto;display:block;left:auto;position:relative;right:auto;top:auto;}
  .site-nav,.site-nav-dropdown,.site-nav-menu,.site-nav-menu>.menu-item{height:100%;}
  .u-small{display:none!important;}
  .site-nav-menu{display:flex;}
  .site-nav-menu .sub-menu{display:none;min-width:100%;position:absolute;white-space:nowrap;}
  .site-nav-menu .menu-item:hover{background-color:var(--color-primary);color:var(--color-txt-inv);}
  .site-nav-menu .sub-menu{padding:0;}
  .site-nav-menu .sub-menu .menu-item:first-of-type{border-top:1px solid var(--color-txt-inv);}
  .site-search{display:none;left:0;-webkit-padding-after:var(--_padding-block);padding-block-end:var(--_padding-block);-webkit-padding-before:var(--_padding-block);padding-block-start:var(--_padding-block);position:fixed;top:var(--header-height);}
  .site-search-form{margin-left:7.8125rem;}
  .site-search-close,.site-search-toggle{display:block;}
  .voila-dashboard-title { 
    display: none;
  }
  
  .site-header-subscribe{margin-inline:1.4rem;}
  .site-nav .site-header-subscribe,.site-nav .language-toggle{display:none;}
}

@media (min-width:984px){
  .voila-dashboard-title {
    display: inherit; 
    position: fixed;
    top: calc(6.5625rem + 1rem);
    left: inherit;
    transform: translateX(calc(3.28125rem - 50%));
    max-width: calc(6.5625rem + 1rem);
  }

  .voila-dashboard-title * {
    text-align: center;
    line-height: 1.1;
  }
}

@media (min-width: 1200px) {
  .site-header-branding-text{display:block;}
}</style>